import React, { useState, useEffect } from "react";

interface Plan {
  id: string;
  planName: string;
  description: string;
}
interface LicenseType {
  id: string;
  name: string;
  code: string;
}

interface PermissionsProps {
  plans: Plan[];
  licenseTypes: LicenseType[];
}

interface Module {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  description: string;
  default_status: boolean;
  default_volume: number;
}

const Permissions: React.FC<PermissionsProps> = ({ plans, licenseTypes }) => {
  const [selectedLicenseType, setSelectedLicenseType] = useState<string>(""); // State to store selected license type
  const [modules, setModules] = useState<Module[]>([]); // State to store the fetched modules
  const [loadingModules, setLoadingModules] = useState(false); // State to handle module loading
  const [error, setError] = useState<string>(""); // State to handle errors

  const token = localStorage.getItem("sessionToken");

  // Function to fetch modules when license type changes
  useEffect(() => {
    const fetchModules = async () => {
      if (!selectedLicenseType) return; // Don't fetch if no license type is selected

      setLoadingModules(true);
      setError("");
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/modules/license-type/${selectedLicenseType}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch modules");
        }
        const data = await response.json();
        setModules(data || []); // Assuming API returns { modules: [...] }
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoadingModules(false);
      }
    };

    fetchModules();
  }, [selectedLicenseType]); // Fetch modules when selectedLicenseType changes

  return (
    <div id="permissions">
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label>Select Plan</label>
            <select name="planName" className="form-control mb-3">
              <option value="">Select Plan</option>
              {plans.map((plan) => (
                <option key={plan.id} value={plan.planName}>
                  {plan.planName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label>Select License Type</label>
            <select
              name="profileName"
              className="form-control mb-3"
              value={selectedLicenseType}
              onChange={(e) => setSelectedLicenseType(e.target.value)} // Set selected license type
            >
              <option value="">Select License Type</option>
              {licenseTypes.map((license) => (
                <option key={license.id} value={license.code}>
                  {license.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Display loading, error, or the fetched modules */}
      {loadingModules ? (
        <p>Loading modules...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : modules.length > 0 ? ( // Check if modules array has items
        <>
        <table className="table">
          <thead>
            <tr>
              <th>Module</th>
              <th>Yes/No</th>
              <th>Volume</th>
            </tr>
          </thead>
          <tbody>
            {modules.map((module, index) => (
              <tr key={index}>
                <td>{module.name}</td>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={module.default_status}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    defaultValue={module.default_volume}
                    style={{ width: "100px" }}
                  />
                </td>
              </tr>
            ))}            
          </tbody>
        </table>
        <table>
            <tbody>
            <tr>
              <td>Billing Cycle Month to Month</td>
              <td>
                <input
                  type="text"
                  style={{ height: "30px", width: "100px" }}
                  name="bill_per_month"
                />
              </td>            
              <td>Billing Cycle 12 Months</td>
              <td>
                <input
                  type="text"
                  style={{ height: "30px", width: "100px" }}
                  name="bill_per_month"
                />
              </td>
              <td>
                <button className="btn btn-primary">Sumit</button>
              </td>
            </tr>
            </tbody>            
        </table>
        </>
      ) : (
        <p>No modules available for this license type.</p>
      )}
    </div>
  );
};

export default Permissions;
