interface UserInfo {
  email: string;
  password: string;
}

interface LoginResponse {
  super_admin_token: string;
}

interface RegisterResponse {
  success: boolean;
  message: string;
}

export const loginUser = async (email: string, password: string): Promise<LoginResponse> => {
  const response = await fetch('https://dev.reelty.app/api/v1/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });
  console.log(response.body);

  if (!response.ok) throw new Error('Login failed');
  const data: LoginResponse = await response.json();
  return data;
};

export const registerUser = async (userInfo: UserInfo): Promise<RegisterResponse> => {
  const response = await fetch('/api/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userInfo),
  });

  if (!response.ok) throw new Error('Registration failed');
  const data: RegisterResponse = await response.json();
  return data;
};
