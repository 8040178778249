import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom"; // Import useParams from React Router
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
// import "./RealtorViewWarehouse.css";

type Warehouse = {
    id: number;
    warehouse_name: string;
    warehouse_id: string;
    street_address1: string;
    street_address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    assigned_teammember?: string;
    warehouse_status: string;
    date_created: string;
};

const SignageViewWarehouse = () => {
  const { id } = useParams<{ id: string }>(); // Get warehouseId from URL params

  const [warehouse, setWarehouse] = useState<Warehouse | null>(null); // To store the warehouse data

  // Fetch the warehouse data based on warehouseId
  useEffect(() => {
    const fetchWarehouseData = async () => {
      try {
        const response = await fetch(`/testdata/data.json`);
        const data = await response.json();
        const selectedWarehouse = data.warehouses.find(
          (warehouse: Warehouse) => warehouse.id === parseInt(id as string)
        );
        setWarehouse(selectedWarehouse);
      } catch (error) {
        console.error("Error fetching warehouse data:", error);
      }
    };

    if (id) {
      fetchWarehouseData();
    }
  }, [id]);

  if (!warehouse) return <div>Loading Warehouse Data...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>View Warehouse Details </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                <div className="table-container">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th colSpan={4}>Warehouse Information </th>
                      </tr>
                      <tr>
                        <th>Warehouse Name: </th>
                        <td>{warehouse.warehouse_name}</td>
                        <th>Warehouse ID: </th>
                        <td>{warehouse.warehouse_id}</td>
                      </tr>
                      <tr>
                        <th colSpan={4}>Warehouse Address</th>
                      </tr>
                      <tr>
                        <th>Street Address 1: </th>
                        <td>{warehouse.street_address1}</td>
                        <th>Street Address 2: </th>
                        <td>{warehouse.street_address2}</td>
                      </tr>
                      <tr>
                        <th> City: </th>
                        <td>{warehouse.city}</td>
                        <th>State: </th>
                        <td>{warehouse.state}</td>
                      </tr>
                      <tr>
                        <th> Country: </th>
                        <td>{warehouse.country}</td>
                        <th>Status: </th>
                        <td>{warehouse.warehouse_status}</td>
                      </tr>
                      <tr>
                        <th colSpan={2}>Assigned Team Members</th>
                        <td colSpan={2}>{warehouse.assigned_teammember}</td>
                      </tr>
                      <tr></tr>
                    </tbody>
                  </table>  
                  </div>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageViewWarehouse;
