import React, { useState, useRef } from 'react';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";

const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

const RealtorAddTeammember = ()=>{

const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ['places'],
  });

  const [address, setAddress] = useState('');
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const formik = useFormik({
    initialValues: {
      photo: '',
      firstname: '',
      lastname: '',
      role: '',
      phone: '',      
      email: '',
      assignedOffices: '',
      permissions: '',
      password: '',
      cpassword: '',      
      status: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'First Name can only contain letters')
        .min(2, 'First Name must be at least 2 characters long')
        .max(50, 'First Name cannot be longer than 50 characters')
        .required('First Name is required'),
      lastname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Last Name can only contain letters')
        .min(2, 'Last Name must be at least 2 characters long')
        .max(50, 'Last Name cannot be longer than 50 characters')
        .required('Last Name is required'),
      role: Yup.string() 
        .required('Role is required'),
      phone: Yup.string()
        .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
        .required('Phone number is required'),
      email: Yup.string()        
        .email('Invalid email address')
        .required('Email is required'),
      assignedOffices: Yup.string() 
        .required('Assign Offices is required'),
      permissions: Yup.string() 
        .required('Permissions is required'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .max(16, 'Password must be max 16 characters long')
        .required('Password is required'),
      cpassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Passwords must match')
        .required('Confirm Password is required'),      
      status: Yup.string()
        .required('Status is required'),
    }),
    onSubmit: (values) => {
      console.log('Form values:', values);
    },
  });  

  // const handlePlaceChanged = () => {
  //   if (autocompleteRef.current) {
  //     const place = autocompleteRef.current.getPlace();
  
  //     const addressComponents = place.address_components;
  
  //     if (addressComponents) {
  //       // Helper function to get specific address component
  //       const getComponent = (type: string) => {
  //         const component = addressComponents.find(c => c.types.includes(type));
  //         return component ? component.long_name : '';
  //       };
  
  //       const streetNumber = getComponent('street_number');
  //       const route = getComponent('route');
  //       const city = getComponent('locality') || getComponent('administrative_area_level_2');
  //       const state = getComponent('administrative_area_level_1');
  //       const postalCode = getComponent('postal_code');
  
  //       const street = `${streetNumber} ${route}`.trim();
  
  //       // Update form values
  //       formik.setFieldValue('street', street);
  //       formik.setFieldValue('city', city);
  //       formik.setFieldValue('state', state);
  //       formik.setFieldValue('zipcode', postalCode);
  //     }
  //   }
  // };
  

  if (!isLoaded) return <div>Loading...</div>;


    return(
        <div className="teammember">
            <div className="card">
                <div className="card-body">
                    <h2>Add Team Member </h2>
                    
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="card mt-4 card-bg-fill">
                  <div className="card-body p-4">                    
                    <div className="p-2 mt-4">
                      <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                          
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              First Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="firstname"
                              name="firstname"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.firstname}
                              required
                            />
                            {formik.touched.firstname && formik.errors.firstname ? (
                              <div className="text-danger">{formik.errors.firstname}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Last Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="lastname"
                              name="lastname"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.lastname}
                              required
                            />
                            {formik.touched.lastname && formik.errors.lastname ? (
                              <div className="text-danger">{formik.errors.lastname}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Job Title/Role{" "}  <span className="text-danger">*</span>
                            </label>
                            <select className="form-control" id="role" name="role" required>
                              <option value="">- Select -</option>
                              <option value="Agent">Agent</option>
                              <option value="Manager">Manager</option>
                              <option value="Admin">Admin</option>
                              <option value="VP">VP</option>
                              <option value="Marketing">Marketing</option>
                              <option value="Legal">Legal</option>
                              <option value="Custom">Custom</option>
                            </select>
                            <div className="invalid-feedback">
                              Please select role
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Phone Number <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="phone"
                              name="phone"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.phone}
                              required
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                              <div className="text-danger">{formik.errors.phone}</div>
                            ) : null}
                          </div>
                          
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Email Address <span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                              required
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div className="text-danger">{formik.errors.email}</div>
                            ) : null}
                          </div>

                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Password <span className="text-danger">*</span>
                            </label>
                            <div className="position-relative auth-pass-inputgroup">
                            <input
                                type="password"
                                className="form-control pe-5 password-input"
                                id="password"
                                name="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                aria-describedby="password"
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                required
                              />
                              <button
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                                type="button"
                                id="password-addon"
                              >
                                <i className="ri-eye-fill align-middle"></i>
                              </button>
                              {formik.touched.password && formik.errors.password ? (
                              <div className="text-danger">{formik.errors.password}</div>
                            ) : null}
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Confirm Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="position-relative auth-pass-inputgroup">
                            <input
                                type="password"
                                className="form-control pe-5 password-input"
                                id="cpassword"
                                name="cpassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.cpassword}
                                aria-describedby="cpassword"
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                required
                              />
                              <button
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                                type="button"
                                id="password-addon"
                              >
                                <i className="ri-eye-fill align-middle"></i>
                              </button>
                              {formik.touched.cpassword && formik.errors.cpassword ? (
                              <div className="text-danger">{formik.errors.cpassword}</div>
                            ) : null}
                            </div>
                          </div>
                        
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Photo/Avatar Upload {" "}                              
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="photo"
                              name="photo"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.photo}
                            />
                            {formik.touched.photo && formik.errors.photo ? (
                              <div className="text-danger">{formik.errors.photo}</div>
                            ) : null}
                          </div>
                          
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                            Assign Offices <span className="text-danger">*</span>
                            </label>                            
                            <input
                              type="text"
                              className="form-control"
                              id="assignedOffices"
                              name="assignedOffices"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.assignedOffices}
                              required
                            />                           
                            {formik.touched.assignedOffices && formik.errors.assignedOffices ? (
                              <div className="text-danger">{formik.errors.assignedOffices}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Permissions/Access Level <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="permissions"
                              name="permissions"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.permissions}
                              required
                            />
                            {formik.touched.permissions && formik.errors.permissions ? (
                              <div className="text-danger">{formik.errors.permissions}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Status <span className="text-danger">*</span>
                            </label>
                            <select className="form-control" id="statuss" name="status" required>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                            <div className="invalid-feedback">
                              Please select status
                            </div>
                          </div>
                          
                          
                        </div>
                        
                        
                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>

                        
                      </form>
                    </div>
                  </div>
                </div>

                
              </div>
            </div>
                </div>
                
            </div>
        </div>
    );
}

export default RealtorAddTeammember;