import React from "react";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "./SuperAdminLicenseRegistrations.css";

const SuperAdminLicenseRegistrations = () => {
  const profiles = [
    "Super Admin",
    "Signage Firm",
    "Realtor Firm",
    "Agent",
    "Installer",
  ];

  const licenses = [
    {
      id: 1,
      company_name: "Company 1",
      license_type: "Brokerage Firm",
      license_status: "Active",
      started_date: "10-05-2024",
      billing_cycle: "12 months",
      created_date: "10-05-2025",
      validity_period: "12 months",
      contact_name: "David",
      payment_status: "paid",
      active_orders: 500,
    },
    {
      id: 2,
      company_name: "Company 2",
      license_type: "Brokerage Firm",
      license_status: "Active",
      started_date: "10-05-2024",
      billing_cycle: "Month-Month",
      created_date: "09-11-2024",
      validity_period: "6 months",
      contact_name: "John",
      payment_status: "paid",
      active_orders: 500,
    },
    {
      id: 3,
      company_name: "Company 3",
      license_type: "Brokerage Firm",
      license_status: "Active",
      started_date: "10-06-2024",
      billing_cycle: "12 months",
      created_date: "10-06-2025",
      validity_period: "12 months",
      contact_name: "Sam",
      payment_status: "paid",
      active_orders: 500,
    },
  ];

  return (
    <div className="roles">
      <div className="card">
        <div className="card-body">
          <h2>License Registrations </h2>
          <div className="text-end">
            <a
              href="/superadmin/add-license"
              className="btn btn-primary float-right"
            >
              Add License
            </a>
          </div>
          <div className="row">
            <div className="table-container">
            <table
              id="transactions"
              className="table table-bordered dt-responsive nowrap table-striped align-middle"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Company Name </th>
                  <th>License Type </th>
                  <th>License Status</th>
                  <th>Started Date</th>
                  <th>Billing cycle</th>
                  <th>Created date</th>
                  <th>License Validity Period</th>
                  <th>Primary Contact Name</th>
                  <th>License Payment Status</th>
                  <th>Active Orders</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {licenses.map((license, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{license.company_name}</td>
                    <td>{license.license_type}</td>
                    <td>{license.license_status}</td>
                    <td>{license.started_date}</td>
                    <td>{license.billing_cycle}</td>
                    <td>{license.created_date}</td>
                    <td>{license.validity_period}</td>
                    <td>{license.contact_name}</td>
                    <td>{license.payment_status}</td>
                    <td>{license.active_orders}</td>
                    <td>
                      <a
                        href="#"
                        className="btn btn-secondary"
                      >
                        <i className="ri-eye-fill align-bottom me-2"></i> 
                      </a>

                      <a className="btn btn-warning waves-effect waves-light">
                        <i className="ri-pencil-fill align-bottom me-2"></i>
                      </a>

                      <a className="btn btn-danger waves-effect waves-light">
                        <i className="ri-delete-bin-fill align-bottom me-2"></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminLicenseRegistrations;
