import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom"; // Import useParams from React Router
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "./RealtorViewOffice.css";

const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

type Office = {
  id: number;
  office_name: string;
  office_id: string;
  office_type: string;
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  office_phone: string;
  no_of_members: string;
  active_listings?: string;
  office_status: string;
  date_created: string;
};

const RealtorViewOffice = () => {
  const { officeId } = useParams<{ officeId: string }>(); // Get officeId from URL params

  const [office, setOffice] = useState<Office | null>(null); // To store the office data

  // Fetch the office data based on officeId
  useEffect(() => {
    const fetchOfficeData = async () => {
      try {
        const response = await fetch(`/testdata/data.json`);
        const data = await response.json();
        const selectedOffice = data.offices.find(
          (office: Office) => office.id === parseInt(officeId as string)
        );
        setOffice(selectedOffice);
      } catch (error) {
        console.error("Error fetching office data:", error);
      }
    };

    if (officeId) {
      fetchOfficeData();
    }
  }, [officeId]);

  if (!office) return <div>Loading Office Data...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>View Office Details </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                <div className="table-container">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th colSpan={4}>Office Information </th>
                      </tr>
                      <tr>
                        <th>Office Name: </th>
                        <td>{office.office_name}</td>
                        <th>Office Type: </th>
                        <td>{office.office_type}</td>
                      </tr>
                      <tr>
                        <th colSpan={4}>Contact Information </th>
                      </tr>
                      <tr>
                        <th colSpan={4}>Office Contact Person </th>
                      </tr>
                      <tr>
                        <th>Contact Person Name: </th>
                        <td>{office.contact_name}</td>
                        <th>Contact Person Email: </th>
                        <td>{office.contact_email}</td>
                      </tr>
                      <tr>
                        <th> Contact Person Phone Number: </th>
                        <td>{office.contact_phone}</td>
                        <th>Office Phone Number: </th>
                        <td>{office.office_phone}</td>
                      </tr>
                    </tbody>
                  </table>  
                  </div>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorViewOffice;
