import React, { useState, useEffect } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";

type Teammember = {
  id: number;
  first_name: string;
  last_name: string;
  role: string;
  phone: string;
  email: string;
  assigned_office: string;
  permissions: string;
  password: string;
  image?: string;
  status: string;
};

const RealtorTeammembers = () => {
  const [teammembers, setTeammembers] = useState<Teammember[]>([]);

  useEffect(() => {
    fetch('/testdata/data.json')
      .then((response) => response.json())
      .then((data) => setTeammembers(data.teammembers))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  if (teammembers.length === 0) {
    return <div>Loading...</div>;
  }
  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Team Members </h2>
          <div className="text-end">
            <a
              href="/realtor/add-teammember"
              className="btn btn-primary float-right"
            >
              Add Team Member
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                <div className="table-container">
                  <table
                    id="example"
                    className="table table-bordered dt-responsive nowrap table-striped align-middle"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Team Member ID</th>
                        <th>Team Member Name</th>
                        <th>Role/Position</th>
                        <th>Associated Offices</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {teammembers.map((teammember) => (
                      <tr key={teammember.id}>
                        <td>{teammember.id}</td>
                        <td>{teammember.first_name} {teammember.last_name}</td>
                        <td>{teammember.role}</td>
                        <td>{teammember.assigned_office}</td>
                        <td>{teammember.status}</td>
                        <td>
                            <Link
                              to={`/realtor/view-teammember/${teammember.id}`}
                              className="btn btn-secondary waves-effect waves-light"
                            >
                              <i className="ri-eye-fill align-bottom me-2"></i>{" "}
                            </Link>

                            <Link to={`/realtor/edit-teammember/${teammember.id}`} className="btn btn-warning waves-effect waves-light">
                              <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                            </Link>

                            <Link to="#" className="btn btn-danger waves-effect waves-light">
                              <i className="ri-delete-bin-fill align-bottom me-2"></i>{" "}
                            </Link>
                          </td>
                      </tr>                      
                       ))} 
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorTeammembers;
