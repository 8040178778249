import React, { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
// import "./RealtorWarehouses.css";

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";

type Warehouse = {
  id: number;
  warehouse_name: string;
  warehouse_id: string;
  street_address1: string;
  street_address2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  assigned_teammember?: string;
  warehouse_status: string;
  date_created: string;
};

const SignageWarehouses = () => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);

  useEffect(() => {
    fetch('/testdata/data.json')
      .then((response) => response.json())
      .then((data) => setWarehouses(data.warehouses))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  if (warehouses.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Warehouses </h2>
          <div className="text-end">
            <a
              href="/signage/add-warehouse"
              className="btn btn-primary float-right"
            >
              Add Warehouse
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                <div className="table-container">
                  <table
                    id="transactions"
                    className="table table-bordered dt-responsive nowrap table-striped align-middle"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "10px" }}>
                          <div className="form-check">
                            <input
                              className="form-check-input fs-15"
                              type="checkbox"
                              id="checkAll"
                              value="option"
                            />
                          </div>
                        </th>
                        <th>S.No.</th>
                        <th>Warehouse Name</th>
                        <th>Warehouse ID</th>
                        <th>Warehouse Status</th>
                        <th>Assigned Team Members</th>
                        <th>Date Created</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {warehouses.map((warehouse, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <div className="form-check">
                              <input
                                className="form-check-input fs-15"
                                type="checkbox"
                                name="checkAll"
                                value="option1"
                              />
                            </div>
                          </th>
                          <td>{index+1}</td>
                          <td>{warehouse.warehouse_name}</td>
                          <td>{warehouse.warehouse_id}</td>                          
                          <td>{warehouse.warehouse_status}</td>
                          <td>{warehouse.assigned_teammember}</td>
                          <td>{warehouse.date_created}</td>
                          <td>
                            <Link
                              to={`/signage/view-warehouse/${warehouse.id}`}
                              className="btn btn-secondary waves-effect waves-light"
                            >
                              <i className="ri-eye-fill align-bottom me-2"></i>{" "}
                            </Link>

                            <Link to={`/signage/edit-warehouse/${warehouse.id}`} className="btn btn-warning waves-effect waves-light">
                              <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                            </Link>

                            <Link to="#" className="btn btn-danger waves-effect waves-light">
                              <i className="ri-delete-bin-fill align-bottom me-2"></i>{" "}
                            </Link>
                          </td>
                        </tr>
                      ))}                      
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageWarehouses;
