import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./components/PrivateRoute";

// Auth Pages
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import TestApi from "./pages/auth/TestApi";

// Registration Pages
import SignageRegistration from "./pages/registrations/SignageRegistration";
import RealtorRegistration from "./pages/registrations/RealtorRegistration";
import AgentRegistration from "./pages/registrations/AgentRegistration";
import InstallerRegistration from "./pages/registrations/InstallerRegistration";

// Signage Dashboard and Layout
import SignageDashboard from "./pages/signage/SignageDashboard";
import SignageLayout from "./layouts/SignageLayout";

// Realtor Layout and Pages
import RealtorLayout from "./layouts/RealtorLayout";
import RealtorDashboard from "./pages/realtor/RealtorDashboard";
import RealtorTeammembers from "./pages/realtor/RealtorTeammembers";
import RealtorAddTeammember from "./pages/realtor/RealtorAddTeammember";
import RealtorViewTeammember from "./pages/realtor/RealtorViewTeammember";
import RealtorEditTeammember from "./pages/realtor/RealtorEditTeammember";
import RealtorOffices from "./pages/realtor/RealtorOffices";
import RealtorAddOffice from "./pages/realtor/RealtorAddOffice";
import RealtorViewOffice from "./pages/realtor/RealtorViewOffice";
import RealtorEditOffice from "./pages/realtor/RealtorEditOffice";
import RealtorAgents from "./pages/realtor/RealtorAgents";
import RealtorAddAgent from "./pages/realtor/RealtorAddAgent";
import RealtorViewAgent from "./pages/realtor/RealtorViewAgent";
import RealtorEditAgent from "./pages/realtor/RealtorEditAgent";
import RealtorSuppliers from "./pages/realtor/RealtorSuppliers";

// Super Admin Layout and Pages
import SuperAdminLayout from "./layouts/SuperAdminLayout";
import SuperAdminDashboard from "./pages/superadmin/SuperAdminDashboard";
import SuperAdminProfiles from "./pages/superadmin/SuperAdminProfiles";
import SuperAdminAddProfile from "./pages/superadmin/SuperAdminAddProfile";
import SuperAdminLicenseRegistrations from "./pages/superadmin/SuperAdminLicenseRegistrations";
import SuperAdminAddLicense from "./pages/superadmin/SuperAdminAddLicense";
import SignageWarehouses from "./pages/signage/SignageWarehouses";
import SignageAddWarehouse from "./pages/signage/SignageAddWarehouse";
import SignageEditWarehouse from "./pages/signage/SignageEditWarehouse";
import SignageViewWarehouse from "pages/signage/SignageViewWarehouse";
import SuperAdminEditProfile from "pages/superadmin/SuperAdminEditProfile";

// Adding root rendering for React DOM
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<App />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/signage/register" element={<SignageRegistration />} />
          <Route path="/realtor/register" element={<RealtorRegistration />} />
          <Route path="/installer/register" element={<InstallerRegistration />} />
          <Route path="/agent/register" element={<AgentRegistration />} />
          <Route path="/testapi" element={<TestApi />} />

          {/* Signage Routes with Layout */}
          <Route element={<SignageLayout />}>
            <Route path="/signage/dashboard" element={<SignageDashboard />} />
            <Route path="/signage/warehouses" element={<SignageWarehouses />} />
            <Route path="/signage/add-warehouse" element={<SignageAddWarehouse />} />
            <Route path="/signage/edit-warehouse/:id" element={<SignageEditWarehouse />} />
            <Route path="/signage/view-warehouse/:id" element={<SignageViewWarehouse />} />
          </Route>

          {/* Realtor Routes with Layout */}
          <Route element={<RealtorLayout />}>
            <Route path="/realtor/dashboard" element={<RealtorDashboard />} />
            <Route path="/realtor/teammembers" element={<RealtorTeammembers />} />
            <Route path="/realtor/add-teammember" element={<RealtorAddTeammember />} />
            <Route path="/realtor/view-teammember/:teammemberId" element={<RealtorViewTeammember />} />
            <Route path="/realtor/edit-teammember/:teammemberId" element={<RealtorEditTeammember />} />
            <Route path="/realtor/offices" element={<RealtorOffices />} />
            <Route path="/realtor/add-office" element={<RealtorAddOffice />} />
            <Route path="/realtor/view-office/:officeId" element={<RealtorViewOffice />} />
            <Route path="/realtor/edit-office/:officeId" element={<RealtorEditOffice />} />
            <Route path="/realtor/agents" element={<RealtorAgents />} />
            <Route path="/realtor/add-agent" element={<RealtorAddAgent />} />
            <Route path="/realtor/view-agent/:agentId" element={<RealtorViewAgent />} />
            <Route path="/realtor/edit-agent/:agentId" element={<RealtorEditAgent />} />
            <Route path="/realtor/suppliers" element={<RealtorSuppliers />} />
          </Route>

          {/* Protected Super Admin Routes with Layout */}
          <Route element={<PrivateRoute />}>
            <Route element={<SuperAdminLayout />}>
              <Route path="/superadmin/dashboard" element={<SuperAdminDashboard />} />
              <Route path="/superadmin/profiles" element={<SuperAdminProfiles />} />
              <Route path="/superadmin/add-profile" element={<SuperAdminAddProfile />} />
              <Route path="/superadmin/edit-profile/:planId" element={<SuperAdminEditProfile />} />
              <Route path="/superadmin/license-registrations" element={<SuperAdminLicenseRegistrations />} />
              <Route path="/superadmin/add-license" element={<SuperAdminAddLicense />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
