import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom"; // Import useParams from React Router
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";

type Agent = {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  license_number: string;
  license_expiry_date: string;
  date_joined: string;
  last_login: string;
  status: string;
};

const RealtorViewAgent = () => {
  const { agentId } = useParams<{ agentId: string }>();
  const [agent, setAgent] = useState<Agent | null>(null);

  const formik = useFormik({
    initialValues: {
      oldpassword: "",
      newpassword: "",
      confirmpassword: "",
    },
    validationSchema: Yup.object({
      oldpassword: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .max(16, "Password must be max 16 characters long")
        .required("Password is required"),
      newpassword: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .max(16, "Password must be max 16 characters long")
        .required("Password is required"),
      confirmpassword: Yup.string()
        .oneOf([Yup.ref("password"), ""], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      console.log("Form values:", values);
    },
  });
  // Fetch the agent data based on agentId
  useEffect(() => {
    const fetchAgentData = async () => {
      try {
        const response = await fetch(`/testdata/data.json`);
        const data = await response.json();
        const selectedAgent = data.agents.find(
          (agent: Agent) => agent.id === parseInt(agentId as string)
        );
        setAgent(selectedAgent);
      } catch (error) {
        console.error("Error fetching agent data:", error);
      }
    };

    if (agentId) {
      fetchAgentData();
    }
  }, [agentId]);

  if (!agent) return <div>Loading Agent Data...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg">
              <img
                src="/assets/images/profile-bg.jpg"
                alt=""
                className="profile-wid-img"
              />
            </div>
          </div>
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
            <div className="row g-4">
              <div className="col-auto">
                <div className="avatar-lg">
                  <img
                    src="/assets/images/users/avatar-1.jpg"
                    alt="user-img"
                    className="img-thumbnail rounded-circle"
                  />
                </div>
              </div>

              <div className="col">
                <div className="p-2">
                  <h3 className="text-white mb-1">
                    {agent.first_name} {agent.last_name}
                  </h3>
                  <p className="text-white text-opacity-75">Agent</p>
                  {/* <div className="hstack text-white-50 gap-1">
                                        <div className="me-2"><i className="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>California, United States</div>
                                        <div>
                                            <i className="ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>Themesbrand
                                        </div>
                                    </div> */}
                </div>
              </div>

              <div className="col-12 col-lg-auto order-last order-lg-0">
                {/* <div className="row text text-white-50 text-center">
                                    <div className="col-lg-6 col-4">
                                        <div className="p-2">
                                            <h4 className="text-white mb-1">24.3K</h4>
                                            <p className="fs-14 mb-0">Followers</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-4">
                                        <div className="p-2">
                                            <h4 className="text-white mb-1">1.3K</h4>
                                            <p className="fs-14 mb-0">Following</p>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div>
                <div className="d-flex profile-wrapper">
                  <ul
                    className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link fs-14 active"
                        data-bs-toggle="tab"
                        href="#overview-tab"
                        role="tab"
                      >
                        <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Profile
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link fs-14"
                        data-bs-toggle="tab"
                        href="#activities"
                        role="tab"
                      >
                        <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Address Information
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link fs-14"
                        data-bs-toggle="tab"
                        href="#projects"
                        role="tab"
                      >
                        <i className="ri-price-tag-line d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">
                          Account Security
                        </span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link fs-14"
                        data-bs-toggle="tab"
                        href="#documents"
                        role="tab"
                      >
                        <i className="ri-folder-4-line d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">Status</span>
                      </a>
                    </li>
                  </ul>
                  <div className="flex-shrink-0">
                    <Link
                      to={`/realtor/edit-agent/${agent.id}`}
                      className="btn btn-success"
                    >
                      <i className="ri-edit-box-line align-bottom"></i> Edit
                      Profile
                    </Link>
                  </div>
                </div>

                <div className="tab-content pt-4 text-muted">
                  <div
                    className="tab-pane active"
                    id="overview-tab"
                    role="tabpanel"
                  >
                    <div className="row">
                      <div className="col-xxl-3">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title mb-3">
                              Contact Information{" "}
                            </h5>
                            <div className="table-responsive">
                              <table className="table table-borderless mb-0">
                                <tbody>                                 
                                  <tr>
                                    <th className="pl-1" scope="row">
                                      Phone Number:
                                    </th>
                                    <td className="text-muted">
                                      {agent.phone}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="pl-1" scope="row">
                                      Secondary Phone Number:
                                    </th>
                                    <td className="text-muted">
                                      {agent.phone}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="pl-1" scope="row">
                                      E-mail :
                                    </th>
                                    <td className="text-muted">
                                      {agent.email}
                                    </td>
                                  </tr>                                  
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xxl-3">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title mb-3">
                              Professional Details{" "}
                            </h5>
                            <div className="table-responsive">
                              <table className="table table-borderless mb-0">
                                <tbody>
                                  <tr>
                                    <th className="pl-1" scope="row">
                                      Brokerage Firm Affiliation:
                                    </th>
                                    <td className="text-muted">
                                      ABCXXXX345
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="pl-1" scope="row">
                                      Agent ID/Code:
                                    </th>
                                    <td className="text-muted">
                                      {agent.id}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="pl-1" scope="row">
                                      License Number:
                                    </th>
                                    <td className="text-muted">
                                      {agent.license_number}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="pl-1" scope="row">
                                      License Expiry Date:
                                    </th>
                                    <td className="text-muted">
                                      {agent.license_expiry_date}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="pl-1" scope="row">
                                      License Status :
                                    </th>
                                    <td className="text-muted">
                                      Active
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="pl-1" scope="row">
                                      Areas of Specialization :
                                    </th>
                                    <td className="text-muted">
                                      
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="activities"
                    role="tabpanel"
                  >
                    <div className="card">
                      <div className="card-body">
                        
                        <div className="row">
                          <div className="col-xxl-3">
                            <div className="card">
                              <div className="card-body">
                                <h5 className="card-title mb-3">
                                  Address Information{" "}
                                </h5>
                                <div className="table-responsive">
                                  <table className="table table-borderless mb-0">
                                    <tbody>
                                      <tr>
                                        <th className="pl-1" scope="row">
                                          Street Name :
                                        </th>
                                        <td className="text-muted">
                                          
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="pl-1" scope="row">
                                          Address Line 2:
                                        </th>
                                        <td className="text-muted">
                                         
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="pl-1" scope="row">
                                          City:
                                        </th>
                                        <td className="text-muted">
                                          
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="pl-1" scope="row">
                                          State :
                                        </th>
                                        <td className="text-muted">
                                          
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="pl-1" scope="row">
                                          Zip Code :
                                        </th>
                                        <td className="text-muted">
                                          
                                        </td>
                                      </tr>
                                      <tr>
                                        <th className="pl-1" scope="row">
                                          Map Location :
                                        </th>
                                        <td className="text-muted">
                                          
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="projects" role="tabpanel">
                  <div className="card">
                    <div className="card-body">
                    <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Old Password <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative auth-pass-inputgroup">
                            <input
                              type="password"
                              className="form-control pe-5 password-input"
                              id="oldpassword"
                              name="oldpassword"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.oldpassword}
                              aria-describedby="password"
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                              required
                            />
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                              type="button"
                              id="password-addon"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                            {formik.touched.newpassword &&
                            formik.errors.newpassword ? (
                              <div className="text-danger">
                                {formik.errors.newpassword}
                              </div>
                            ) : null}
                          </div>
                        </div>
                    <div className="col-md-6 mb-3">
                          <label className="form-label">
                            New Password <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative auth-pass-inputgroup">
                            <input
                              type="password"
                              className="form-control pe-5 password-input"
                              id="password"
                              name="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.newpassword}
                              aria-describedby="password"
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                              required
                            />
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                              type="button"
                              id="password-addon"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                            {formik.touched.newpassword &&
                            formik.errors.newpassword ? (
                              <div className="text-danger">
                                {formik.errors.newpassword}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          Confirm Password{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="position-relative auth-pass-inputgroup">
                          <input
                            type="password"
                            className="form-control pe-5 password-input"
                            id="cpassword"
                            name="cpassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmpassword}
                            aria-describedby="cpassword"
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            required
                          />
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                            type="button"
                            id="password-addon"
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                          {formik.touched.confirmpassword &&
                          formik.errors.confirmpassword ? (
                            <div className="text-danger">
                              {formik.errors.confirmpassword}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="mt-4">
                        <button className="btn btn-success" type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="documents" role="tabpanel">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-1">
                        <h5 className="card-title flex-grow-1 mb-0">Status:</h5>
                      </div>
                      <div className="">Active</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorViewAgent;
