import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "./RealtorSuppliers.css";

const RealtorSuppliers = () => {
  
  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Suppliers </h2>          
          <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-12">
                  <div className="card">
                      <div className="card-header border-0 rounded">
                          <div className="row g-2">
                              <div className="col-xl-3">
                                  <div className="search-box">
                                      <input type="text" className="form-control" id="searchResultList" placeholder="Search for sellers &amp; owner name or something..." /> <i className="ri-search-line search-icon"></i>
                                  </div>
                              </div>                                
                                <div className="col-xxl-3 ms-auto">
                                    <div>
                                        <div className="choices" data-type="select-one" role="listbox" aria-haspopup="true" aria-expanded="false"><div className="choices__inner">
                                            <select className="form-control choices__input" id="category-select" data-choice="active">
                                                <option value="All" data-custom-properties="[object Object]">Select Categories</option>
                                            </select>
                                        <div className="choices__list choices__list--single">
                                            
                                        </div>
                                    </div>
                                    <div className="choices__list choices__list--dropdown" aria-expanded="false">
                                        <div className="choices__list" role="listbox">
                                            <div id="choices--category-select-item-choice-1" className="choices__item choices__item--choice choices__item--selectable is-highlighted" role="option" data-choice="" data-id="1" data-value="All" data-select-text="Press to select" data-choice-selectable="" aria-selected="true">All</div>
                                            <div id="choices--category-select-item-choice-2" className="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="2" data-value="Computers &amp; Electronics" data-select-text="Press to select" data-choice-selectable="">Computers &amp; Electronics</div>
                                            <div id="choices--category-select-item-choice-3" className="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="3" data-value="Food Service" data-select-text="Press to select" data-choice-selectable="">Food Service</div>
                                            <div id="choices--category-select-item-choice-4" className="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="4" data-value="Health &amp; Medicine" data-select-text="Press to select" data-choice-selectable="">Health &amp; Medicine</div>
                                            <div id="choices--category-select-item-choice-5" className="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="5" data-value="Manufacturer" data-select-text="Press to select" data-choice-selectable="">Manufacturer</div>
                                            <div id="choices--category-select-item-choice-6" className="choices__item choices__item--choice choices__item--selectable" role="option" data-choice="" data-id="6" data-value="Retailer" data-select-text="Press to select" data-choice-selectable="">Retailer</div>
                                            <div id="choices--category-select-item-choice-7" className="choices__item choices__item--choice is-selected choices__item--selectable" role="option" data-choice="" data-id="7" data-value="All" data-select-text="Press to select" data-choice-selectable="">Select Categories</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                        <div className="col-lg-auto">
                            <div className="hstack gap-2">
                                <button type="button" className="btn btn-danger"><i className="ri-equalizer-fill me-1 align-bottom"></i> Filters</button>
                                <button className="btn btn-success" data-bs-toggle="modal" data-bs-target="#addSeller"><i className="ri-add-fill me-1 align-bottom"></i> Add Supplier</button>
                            </div>
                        </div> 
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4" id="seller-list">
            <div className="col-xl-3 col-lg-6">        
              <div className="card ribbon-box right overflow-hidden">            
                <div className="card-body text-center p-4">                
                  <div className="ribbon ribbon-info ribbon-shape trending-ribbon">
                    <i className="ri-flashlight-fill text-white align-bottom"></i> 
                    <span className="trending-ribbon-text">Trending</span>
                  </div>                
                  <img src="/assets/images/companies/img-1.png" alt="img-1.png" height="45" />               
                  <h5 className="mb-1 mt-4"><a href="apps-ecommerce-seller-details.html" className="link-primary">Supplier Name</a></h5>                
                  <p className="text-muted mb-4">Category</p>                
                  <div className="row justify-content-center">                    
                    <div className="col-lg-8">                        
                      <div id="chart-seller8" data-colors="[&quot;--vz-danger&quot;]" dir="ltr" style={{minHeight: '50px' }}>
                        <div id="apexcharts9cgbb166" className="apexcharts-canvas apexcharts9cgbb166 apexcharts-theme-light" style={{width: '123px', height: '50px'}}>
                          
                        <div className="apexcharts-tooltip apexcharts-theme-light" style={{left: '9.30729px', top: '30.5px'}}>
                          <div className="apexcharts-tooltip-series-group apexcharts-active" style={{order: '1', display: 'flex'}}>
                            <span className="apexcharts-tooltip-marker" style={{backgroundColor: 'rgb(240, 101, 72)', display: 'none'}}></span>
                            <div className="apexcharts-tooltip-text" style={{fontFamily: 'Helvetica, Arial, sans-serif', fontSize: '12px'}}>
                              <div className="apexcharts-tooltip-y-group">
                                <span className="apexcharts-tooltip-text-y-label"></span>
                                <span className="apexcharts-tooltip-text-y-value">36</span>
                              </div>
                              <div className="apexcharts-tooltip-goals-group">
                                <span className="apexcharts-tooltip-text-goals-label"></span>
                                <span className="apexcharts-tooltip-text-goals-value"></span>
                              </div>
                              <div className="apexcharts-tooltip-z-group">
                                <span className="apexcharts-tooltip-text-z-label"></span>
                                <span className="apexcharts-tooltip-text-z-value"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                          <div className="apexcharts-yaxistooltip-text"></div>
                        </div>
                      </div>
                    </div>                    
                  </div>                
                </div>                
                <div className="row mt-4">                    
                  <div className="col-lg-6 border-end-dashed border-end">                        
                    <h5>452</h5>                        
                    <span className="text-muted">Item Stock</span>                    
                  </div>                    
                  <div className="col-lg-6">                        
                    <h5>$45,415</h5>                        
                    <span className="text-muted">Wallet Balance</span>                    
                  </div>                
                </div>                
                <div className="mt-4">                    
                  <a href="#" className="btn btn-light w-100">View Details</a>                
                </div>            
              </div>        
            </div>              
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default RealtorSuppliers;