import React from "react";

const NavbarBrandBox = () => {
  return (
    <>
    <div className="navbar-brand-box horizontal-logo">
      <a href="/" className="logo logo-dark">
        <span className="logo-sm">
          <img src="assets/images/logo-sm.png" alt="" height="22" />
        </span>
        <span className="logo-lg">
          <img src="assets/images/logo-dark.png" alt="" height="17" />
        </span>
      </a>

      <a href="/" className="logo logo-light">
        <span className="logo-sm">
          <img src="assets/images/logo-sm.png" alt="" height="22" />
        </span>
        <span className="logo-lg">
          <img src="assets/images/logo-light.png" alt="" height="17" />
        </span>
      </a>
    </div>
    </>
  );
};

export default NavbarBrandBox;
