import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface Plan {
    id: string;
    planName: string;
    description: string;
}  
  
interface PlanProfilesProps {
  plans: Plan[];
  loading: boolean;
  error: string;
  deletePlan: (id: string) => void;
}

const PlanProfiles: React.FC<PlanProfilesProps> = ({ plans, loading, error, deletePlan  }) => {
  
  return (
    <div id="profiles">
      <div className="text-end">
        <Link to="/superadmin/add-profile" className="btn btn-primary float-right">
          Add Plan Profile
        </Link>
      </div>
      {loading ? (
        <p>Loading plans...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <table className="table table-bordered dt-responsive nowrap table-striped align-middle">
          <thead>
            <tr>
              <th>ID</th>
              <th>Plan Profile</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {plans.map((plan, index) => (
              <tr key={plan.id}>
                <td>{index + 1}</td>
                <td>{plan.planName}</td>
                <td>
                    <Link
                        to={`/superadmin/edit-profile/${plan.id}`}
                        className="btn btn-warning waves-effect waves-light"
                    >
                        <i className="ri-pencil-fill align-bottom me-2"></i>
                    </Link>{" "}
                    <button
                        onClick={() => deletePlan(plan.id)}
                        className="btn btn-danger waves-effect waves-light"
                    >
                        <i className="ri-delete-bin-fill align-bottom me-2"></i>
                    </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PlanProfiles;
