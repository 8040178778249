import React, { useState, useRef, useEffect } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";

const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

interface LicenseType {
  id: string;
  name: string;
  code: string;
}

interface Module {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  description: string;
  default_status: boolean;
  default_volume: number;
}

const SuperAdminAddLicense = () => {
  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedLicenseType, setSelectedLicenseType] = useState<string>(""); // State to store selected license type
  const [modules, setModules] = useState<Module[]>([]); // State to store the fetched modules
  const [loadingModules, setLoadingModules] = useState(false); // State to handle module loading
  const [error, setError] = useState<string>(""); // State to handle errors

  const token = localStorage.getItem("sessionToken");

  useEffect(() => {
    const fetchLicenseTypes = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/license-types",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setLicenseTypes(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseTypes();
  }, []);

  // Function to fetch modules when license type changes
  useEffect(() => {
    const fetchModules = async () => {
      if (!selectedLicenseType) return; // Don't fetch if no license type is selected

      setLoadingModules(true);
      setError("");
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/modules/license-type/${selectedLicenseType}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch modules");
        }
        const data = await response.json();
        setModules(data || []); // Assuming API returns { modules: [...] }
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoadingModules(false);
      }
    };

    fetchModules();
  }, [selectedLicenseType]); // Fetch modules when selectedLicenseType changes

  const profiles = ["Signage Firm", "Brokerage Firm", "Agent", "Installer"];

  const profilegroups = ["Group 1", "Group 2", "Group 3", "Group 4"];

  const permissions = [
    {
      name: "Team Members",
      perm: true,
      volume: 100,
    },
    {
      name: "Offices",
      perm: true,
      volume: 100,
    },
    {
      name: "Suppliers",
      perm: true,
      volume: 100,
    },
    {
      name: "Inventory",
      perm: false,
      volume: 100,
    },
    {
      name: "Agents",
      perm: true,
      volume: 100,
    },
    {
      name: "Installers",
      perm: true,
      volume: 100,
    },
    {
      name: "Orders",
      perm: true,
      volume: 100,
    },
  ];

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ["places"],
  });

  const [address, setAddress] = useState("");
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const formik = useFormik({
    initialValues: {
      companyName: "",
      role: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      status: "",
      validityDuration: "",
      billingCycle: "",
      paymentMethod: "",
      licenseType: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .min(2, "Company name must be at least 2 characters long")
        .max(50, "Company name cannot be longer than 50 characters"),
      role: Yup.string()
        .min(2, "Office type must be at least 2 characters long")
        .max(50, "Office type cannot be longer than 50 characters"),
      firstName: Yup.string()
        .min(2, "First Name must be at least 2 characters long")
        .max(50, "First Name cannot be longer than 50 characters")
        .required("First Name is required"),
      lastName: Yup.string()
        .min(2, "Last Name must be at least 2 characters long")
        .max(50, "Last Name cannot be longer than 50 characters")
        .required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Phone number is required"),
      streetAddress1: Yup.string()
        .min(2, "Street address must be at least 8 characters long")
        .max(16, "Street address must be max 16 characters long")
        .required("Street Address 1 is required"),
      streetAddress2: Yup.string()
        .min(2, "Street address must be at least 8 characters long")
        .max(16, "Street address must be max 16 characters long"),
      city: Yup.string()
        .min(2, "City must be at least 2 characters long")
        .max(50, "City cannot be longer than 50 characters")
        .required("City is required"),
      state: Yup.string()
        .min(2, "State must be at least 2 characters long")
        .max(50, "State cannot be longer than 50 characters")
        .required("State is required"),
      zipcode: Yup.string().required("Zip code is required"),
      country: Yup.string()
        .min(2, "Country must be at least 2 characters long")
        .max(50, "Country cannot be longer than 50 characters"),
      status: Yup.string().required("Status is required"),
      validityDuration: Yup.string().required("Validity Duration is required"),
      billingCycle: Yup.string().required("Billing cycle is required"),
      paymentMethod: Yup.string().required("Payment method is required"),
      licenseType: Yup.string().required("License type is required"),
    }),
    onSubmit: (values) => {
      console.log("Form values:", values);
    },
  });

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();

      const addressComponents = place.address_components;

      if (addressComponents) {
        // Helper function to get specific address component
        const getComponent = (type: string) => {
          const component = addressComponents.find((c) =>
            c.types.includes(type)
          );
          return component ? component.long_name : "";
        };

        const streetNumber = getComponent("street_number");
        const route = getComponent("route");
        const city =
          getComponent("locality") ||
          getComponent("administrative_area_level_2");
        const state = getComponent("administrative_area_level_1");
        const postalCode = getComponent("postal_code");

        const street = `${streetNumber} ${route}`.trim();

        // Update form values
        formik.setFieldValue("streetAddress1", street);
        formik.setFieldValue("city", city);
        formik.setFieldValue("state", state);
        formik.setFieldValue("zipcode", postalCode);
      }
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="roles">
      <div className="card">
        <div className="card-body">
          <h2>Add License </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Company Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="companyName"
                            name="companyName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.companyName}
                          />
                          {formik.touched.companyName &&
                          formik.errors.companyName ? (
                            <div className="text-danger">
                              {formik.errors.companyName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Role</label>
                          <input
                            type="text"
                            className="form-control"
                            id="role"
                            name="role"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.role}
                          />
                          {formik.touched.role && formik.errors.role ? (
                            <div className="text-danger">
                              {formik.errors.role}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <div className="text-danger">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="text-danger">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            required
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-danger">
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Street Address 1{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Autocomplete
                            onLoad={(autocomplete) =>
                              (autocompleteRef.current = autocomplete)
                            }
                            onPlaceChanged={handlePlaceChanged}
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="streetAddress1"
                              name="streetAddress1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.streetAddress1}
                              required
                            />
                          </Autocomplete>
                          {formik.touched.streetAddress1 &&
                          formik.errors.streetAddress1 ? (
                            <div className="text-danger">
                              {formik.errors.streetAddress1}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Street Address 2{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="streetAddress2"
                            name="streetAddress2"
                            placeholder="Enter Address line 2"
                          />
                          <div className="invalid-feedback">
                            Please enter Address line 2
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            City <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                            required
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div className="text-danger">
                              {formik.errors.city}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            State <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="state"
                            name="state"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.state}
                            required
                          />
                          {formik.touched.state && formik.errors.state ? (
                            <div className="text-danger">
                              {formik.errors.state}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Zip code <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="zipcode"
                            name="zipcode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.zipcode}
                            required
                          />
                          {formik.touched.zipcode && formik.errors.zipcode ? (
                            <div className="text-danger">
                              {formik.errors.zipcode}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">Country</label>
                          <input
                            type="text"
                            className="form-control"
                            id="country"
                            name="country"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.country}
                          />
                          {formik.touched.country && formik.errors.country ? (
                            <div className="text-danger">
                              {formik.errors.country}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">Status </label>
                          <select className="form-control" id="statuss">
                            <option value="">- Select -</option>
                            <option value="Active">Active </option>
                            <option value="Inactive">Inactive </option>
                            <option value="Expired">Expired </option>
                            <option value="Pending">Pending </option>
                            <option value="Suspended">Suspended </option>
                          </select>
                          <div className="invalid-feedback">
                            Please select a Status
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            License validity duration{" "}
                          </label>
                          <select
                            className="form-control"
                            id="validityDuration"
                          >
                            <option value="">- Select -</option>
                            <option value="1 month">1 month</option>
                            <option value="3 months">3 months</option>
                            <option value="6 months">6 months</option>
                            <option value="12 months">12 months</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select a License validity duration
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">Billing cycle </label>
                          <select className="form-control" id="billingCycle">
                            <option value="">- Select -</option>
                            <option value="Month-Month">Month-Month</option>
                            <option value="12 months">12 months</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select a Billing cycle
                          </div>
                        </div>

                        {/* <div className="col-md-6 mb-3">
                          <label className="form-label">
                          Billing cycle Month to Month 
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bill_per_month"
                            name="bill_per_month"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value=""
                          />
                          <div className="invalid-feedback">
                            Please enter Billing cycle cost
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                          Billing Cycle 12 Months 
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bill_per_year"
                            name="bill_per_year"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value=""
                          />
                          <div className="invalid-feedback">
                            Please enter Billing cycle cost
                          </div>
                        </div> */}

                        <div className="col-md-6 mb-3">
                          <label className="form-label">Payment method </label>
                          <select className="form-control" id="paymentMethod">
                            <option value="">- Select -</option>
                            <option value="Card">Card</option>
                            <option value="Bank transfer">Bank transfer</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select a Payment method
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group">
                            <label>Billing Cycle Month to Month</label>
                            <input
                              type="text"
                              name="bill_per_month"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-group">
                            <label>Billing Cycle 12 Months</label>
                            <input
                              type="text"
                              name="bill_per_month"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Select License Type</label>
                                <select
                                  name="profileName"
                                  className="form-control mb-3"
                                  value={selectedLicenseType}
                                  onChange={(e) =>
                                    setSelectedLicenseType(e.target.value)
                                  } // Set selected license type
                                >
                                  <option value="">Select License Type</option>
                                  {licenseTypes.map((license) => (
                                    <option
                                      key={license.id}
                                      value={license.code}
                                    >
                                      {license.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="permissions-list">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Module</th>
                                      <th>Yes/No</th>
                                      <th>Volume</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {modules.map((module, index) => (
                                      <tr key={index}>
                                        <td>{module.name}</td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            defaultChecked={
                                              module.default_status
                                            }
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            defaultValue={module.default_volume}
                                            style={{ width: "100px" }}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                  {/* <tbody>
                                    {
                                        (permissions.map((perm, index)=>(
                                            <tr key={index}>
                                                <td>{perm.name}</td>
                                                <td><input type="checkbox" defaultValue="false" id="perm+${index}" /></td>
                                                <td><input type="text" defaultValue={perm.volume} style={{height:'30px',width:'100px'}} /></td>
                                            </tr>
                                        )))
                                    }
                                    <tr>
                                      <td colSpan={2}>Billing Cycle Month to Month</td>
                                      <td><input type="text" style={{height:'30px',width:'100px'}} name="bill_per_month" /></td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2}>Billing Cycle 12 Months</td>
                                      <td><input type="text" style={{height:'30px',width:'100px'}} name="bill_per_month" /></td>
                                    </tr>
                                    </tbody> */}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminAddLicense;
