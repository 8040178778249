import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";

type Teammember = {
  id: number;
  first_name: string;
  last_name: string;
  role: string;
  phone: string;
  email: string;
  assigned_office: string;
  permissions: string;
  password: string;
  image?: string;
  status: string;
};

const RealtorEditTeammember = () => {
  const { teammemberId } = useParams<{ teammemberId: string }>();
  const [teammember, setTeammember] = useState<Teammember | null>(null);

  // Set initial values for formik
  const [initialValues, setInitialValues] = useState({
    photo: "",
    firstname: "",
    lastname: "",
    role: "",
    phone: "",
    assignedOffices: "",
    permissions: "",
    status: "",
  });

  // Fetch the teammember data based on teammemberId
  useEffect(() => {
    const fetchTeammemberData = async () => {
      try {
        const response = await fetch(`/testdata/data.json`);
        const data = await response.json();
        const selectedTeammember = data.teammembers.find(
          (teammember: Teammember) =>
            teammember.id === parseInt(teammemberId as string)
        );
        setTeammember(selectedTeammember);

        // Update formik initial values when teammember data is loaded
        if (selectedTeammember) {
          setInitialValues({
            photo: selectedTeammember.image || "",
            firstname: selectedTeammember.first_name || "",
            lastname: selectedTeammember.last_name || "",
            role: selectedTeammember.role || "",
            phone: selectedTeammember.phone || "",
            assignedOffices: selectedTeammember.assigned_office || "",
            permissions: selectedTeammember.permissions || "",
            status: selectedTeammember.status || "",
          });
        }
      } catch (error) {
        console.error("Error fetching teammember data:", error);
      }
    };

    if (teammemberId) {
      fetchTeammemberData();
    }
  }, [teammemberId]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true, // Important to allow formik to reinitialize after setting initialValues
    validationSchema: Yup.object({
      firstname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name can only contain letters")
        .min(2, "First Name must be at least 2 characters long")
        .max(50, "First Name cannot be longer than 50 characters")
        .required("First Name is required"),
      lastname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name can only contain letters")
        .min(2, "Last Name must be at least 2 characters long")
        .max(50, "Last Name cannot be longer than 50 characters")
        .required("Last Name is required"),
      role: Yup.string().required("Role is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Phone number is required"),
      assignedOffices: Yup.string().required("Assign Offices is required"),
      permissions: Yup.string().required("Permissions is required"),
      status: Yup.string().required("Status is required"),
    }),
    onSubmit: (values) => {
      console.log("Form values:", values);
    },
  });

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Edit Team Member </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstname"
                            name="firstname"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstname}
                            required
                          />
                          {formik.touched.firstname &&
                          formik.errors.firstname ? (
                            <div className="text-danger">
                              {formik.errors.firstname}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastname"
                            name="lastname"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastname}
                            required
                          />
                          {formik.touched.lastname && formik.errors.lastname ? (
                            <div className="text-danger">
                              {formik.errors.lastname}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Job Title/Role{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="role"
                            name="role"
                            required
                          >
                            <option value="">- Select -</option>
                            <option value="Agent">Agent</option>
                            <option value="Manager">Manager</option>
                            <option value="Admin">Admin</option>
                            <option value="VP">VP</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Legal">Legal</option>
                            <option value="Custom">Custom</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select role
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Phone Number <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            required
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-danger">
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Photo/Avatar Upload{" "}
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="photo"
                            name="photo"
                            onChange={(event) => {
                              formik.setFieldValue("photo", null); // Handle file selection
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.photo && formik.errors.photo ? (
                            <div className="text-danger">
                              {formik.errors.photo}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Assign Offices{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="assignedOffices"
                            name="assignedOffices"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.assignedOffices}
                            required
                          />
                          {formik.touched.assignedOffices &&
                          formik.errors.assignedOffices ? (
                            <div className="text-danger">
                              {formik.errors.assignedOffices}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Permissions/Access Level{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="permissions"
                            name="permissions"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.permissions}
                            required
                          />
                          {formik.touched.permissions &&
                          formik.errors.permissions ? (
                            <div className="text-danger">
                              {formik.errors.permissions}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="statuss"
                            name="status"
                            required
                          >
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select status
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorEditTeammember;
