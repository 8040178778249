import React, { useState, useEffect } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";

type Agent = {
  id: number;
  first_name: string;
  last_name: string;  
  phone: string;
  email: string;
  license_number: string;
  license_expiry_date: string;
  date_joined: string;
  last_login: string; 
  status: string;
};

const RealtorAgents = () => {
  const [agents, setAgents] = useState<Agent[]>([]);

  useEffect(() => {
    fetch('/testdata/data.json')
      .then((response) => response.json())
      .then((data) => setAgents(data.agents))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  if (agents.length === 0) {
    return <div>Loading...</div>;
  }
  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Agents </h2>
          <div className="text-end">
            <a
              href="/realtor/add-agent"
              className="btn btn-primary float-right"
            >
              Add Agent
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                <div className="table-container">
                  <table
                    id="example"
                    className="table table-bordered dt-responsive nowrap table-striped align-middle"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Agent ID</th>
                        <th>Agent Name</th>
                        <th>License Number</th>
                        <th>License Expiry Date</th>
                        <th>Status</th>
                        <th>Date Joined</th>
                        <th>Last Login</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {agents.map((agent) => (
                      <tr key={agent.id}>
                        <td>{agent.id}</td>
                        <td>{agent.first_name} {agent.last_name}</td>
                        <td>{agent.license_number}</td>
                        <td>{agent.license_expiry_date}</td>
                        <td>{agent.status}</td>
                        <td>{agent.date_joined}</td>
                        <td>{agent.last_login}</td>                        
                        <td>{agent.email}</td>
                        <td>{agent.phone}</td>
                        
                        <td>
                            <Link
                              to={`/realtor/view-agent/${agent.id}`}
                              className="btn btn-secondary waves-effect waves-light"
                            >
                              <i className="ri-eye-fill align-bottom me-2"></i>{" "}
                            </Link>

                            <Link to={`/realtor/edit-agent/${agent.id}`} className="btn btn-warning waves-effect waves-light">
                              <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                            </Link>

                            <Link to="#" className="btn btn-danger waves-effect waves-light">
                              <i className="ri-delete-bin-fill align-bottom me-2"></i>{" "}
                            </Link>
                          </td>
                      </tr>                      
                       ))} 
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorAgents;
