import React from "react";
import { Link } from "react-router-dom";

const ProfileGroups: React.FC = () => {
  return (
    <div id="profileGroups">
      <div className="text-end">
        <Link to="#" className="btn btn-primary float-right">
          Add Group
        </Link>
      </div>
      <table className="table table-bordered dt-responsive nowrap table-striped align-middle">
        <thead>
          <tr>
            <th>Group ID</th>
            <th>Group Name</th>
            <th>Plan Profiles</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Group 1</td>
            <td>Agent Plan 1, Agent plan 2</td>
            <td>
              <Link
                to="#"
                className="btn btn-secondary waves-effect waves-light"
              >
                <i className="ri-eye-fill align-bottom me-2"></i>
              </Link>{" "}
              <Link to="#" className="btn btn-warning waves-effect waves-light">
                <i className="ri-pencil-fill align-bottom me-2"></i>
              </Link>{" "}
              <Link to="#" className="btn btn-danger waves-effect waves-light">
                <i className="ri-delete-bin-fill align-bottom me-2"></i>
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProfileGroups;
