import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface PrivateRouteProps {
  children?: JSX.Element;  // Make children optional
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { token } = useAuth();

  if (!token) {
    // If user is not authenticated, redirect to login
    return <Navigate to="/login" replace />;
  }

  // Render children if passed, or render Outlet for nested routes
  return children ? children : <Outlet />;
};

export default PrivateRoute;
