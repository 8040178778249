import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom"; // Import useParams from React Router
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";

type Teammember = {
    id: number;
    first_name: string;
    last_name: string;
    role: string;
    phone: string;
    email: string;
    assigned_office: string;
    permissions: string;
    password: string;
    image?: string;
    status: string;
  };

const RealtorViewTeammember = () => {
  const { teammemberId } = useParams<{ teammemberId: string }>(); 

  const [teammember, setTeammember] = useState<Teammember | null>(null); 

  // Fetch the office data based on officeId
  useEffect(() => {
    const fetchTeammemberData = async () => {
      try {
        const response = await fetch(`/testdata/data.json`);
        const data = await response.json();
        const selectedTeammember = data.teammembers.find(
          (teammember: Teammember) => teammember.id === parseInt(teammemberId as string)
        );
        setTeammember(selectedTeammember);
      } catch (error) {
        console.error("Error fetching teammember data:", error);
      }
    };

    if (teammemberId) {
        fetchTeammemberData();
    }
  }, [teammemberId]);

  if (!teammember) return <div>Loading Teammember Data...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          
          <div className="profile-foreground position-relative mx-n4 mt-n4">
                <div className="profile-wid-bg">
                    <img src="/assets/images/profile-bg.jpg" alt="" className="profile-wid-img" />
                </div>
            </div>
            <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
                        <div className="row g-4">
                            <div className="col-auto">
                                <div className="avatar-lg">
                                    <img src="/assets/images/users/avatar-1.jpg" alt="user-img" className="img-thumbnail rounded-circle" />
                                </div>
                            </div>
                            
                            <div className="col">
                                <div className="p-2">
                                    <h3 className="text-white mb-1">{teammember.first_name} {teammember.last_name}</h3>
                                    <p className="text-white text-opacity-75">{teammember.role}</p>
                                    {/* <div className="hstack text-white-50 gap-1">
                                        <div className="me-2"><i className="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>California, United States</div>
                                        <div>
                                            <i className="ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>Themesbrand
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            
                            <div className="col-12 col-lg-auto order-last order-lg-0">
                                {/* <div className="row text text-white-50 text-center">
                                    <div className="col-lg-6 col-4">
                                        <div className="p-2">
                                            <h4 className="text-white mb-1">24.3K</h4>
                                            <p className="fs-14 mb-0">Followers</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-4">
                                        <div className="p-2">
                                            <h4 className="text-white mb-1">1.3K</h4>
                                            <p className="fs-14 mb-0">Following</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            

                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                <div className="d-flex profile-wrapper">
                                    
                                    <ul className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link fs-14 active" data-bs-toggle="tab" href="#overview-tab" role="tab">
                                                <i className="ri-airplay-fill d-inline-block d-md-none"></i> <span className="d-none d-md-inline-block">Profile</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link fs-14" data-bs-toggle="tab" href="#activities" role="tab">
                                                <i className="ri-list-unordered d-inline-block d-md-none"></i> <span className="d-none d-md-inline-block">Activities</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link fs-14" data-bs-toggle="tab" href="#projects" role="tab">
                                                <i className="ri-price-tag-line d-inline-block d-md-none"></i> <span className="d-none d-md-inline-block">Projects</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link fs-14" data-bs-toggle="tab" href="#documents" role="tab">
                                                <i className="ri-folder-4-line d-inline-block d-md-none"></i> <span className="d-none d-md-inline-block">Documents</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="flex-shrink-0">
                                    <Link to={`/realtor/edit-teammember/${teammember.id}`} className="btn btn-success"><i className="ri-edit-box-line align-bottom"></i> Edit Profile</Link>
                                    </div>
                                </div>
                                
                                <div className="tab-content pt-4 text-muted">
                                    <div className="tab-pane active" id="overview-tab" role="tabpanel">
                                        <div className="row">
                                            <div className="col-xxl-3">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title mb-3">Info</h5>
                                                        <div className="table-responsive">
                                                            <table className="table table-borderless mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Full Name :</th>
                                                                        <td className="text-muted">{teammember.first_name} {teammember.last_name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Role :</th>
                                                                        <td className="text-muted">{teammember.role}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Phone :</th>
                                                                        <td className="text-muted">{teammember.phone}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">E-mail :</th>
                                                                        <td className="text-muted">{teammember.email}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Location :</th>
                                                                        <td className="text-muted">California, United States
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Status :</th>
                                                                        <td className="text-muted">{teammember.status}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                               
                                                {/* <div className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title mb-4">Skills</h5>
                                                        <div className="d-flex flex-wrap gap-2 fs-15">
                                                            <a href="javascript:void(0);" className="badge bg-primary-subtle text-primary">Photoshop</a>
                                                            <a href="javascript:void(0);" className="badge bg-primary-subtle text-primary">illustrator</a>
                                                            <a href="javascript:void(0);" className="badge bg-primary-subtle text-primary">HTML</a>
                                                            <a href="javascript:void(0);" className="badge bg-primary-subtle text-primary">CSS</a>
                                                            <a href="javascript:void(0);" className="badge bg-primary-subtle text-primary">Javascript</a>
                                                            <a href="javascript:void(0);" className="badge bg-primary-subtle text-primary">Php</a>
                                                            <a href="javascript:void(0);" className="badge bg-primary-subtle text-primary">Python</a>
                                                        </div>
                                                    </div>
                                                </div> */}

                                               
                                                
                                            </div>
                                            
                                            <div className="col-xxl-9">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h5 className="card-title mb-3">About</h5>
                                                        <p>Hi I'm Anna Adame, It will be as simple as Occidental; in fact, it will be Occidental. To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is European languages are members of the same family.</p>
                                                        <p>You always want to make sure that your fonts work well together and try to limit the number of fonts you use to three or less. Experiment and play around with the fonts that you already have in the software you’re working with reputable font websites. This may be the most commonly encountered tip I received from the designers I spoke with. They highly encourage that you use different fonts in one design, but do not over-exaggerate and go overboard.</p>
                                                        
                                                        
                                                    </div>
                                                    
                                                </div>
                                                

                                                <div className="card">
                                                    <div className="card-body">                                                    
                                                    
                                                        <div className="swiper project-swiper mt-n4">
                                                        <h5 className="card-title">Assigned Offices </h5>
                                                            
                                                            <div className="swiper-wrapper">
                                                                <div className="swiper-slide">
                                                                    <div className="card profile-project-card shadow-none profile-project-success mb-0 material-shadow">
                                                                        <div className="card-body p-4">
                                                                            <div className="d-flex">
                                                                                <div className="flex-grow-1 text-muted overflow-hidden">
                                                                                    <h5 className="fs-14 text-truncate mb-1">
                                                                                        <a href="#" className="text-body">Office 1</a>
                                                                                    </h5>
                                                                                    <p className="text-muted text-truncate mb-0"> Location : <span className="fw-semibold text-body"></span></p>
                                                                                </div>                                                                                
                                                                            </div>
                                                                            <div className="d-flex mt-4">
                                                                                <div className="flex-grow-1">
                                                                                    <div className="d-flex align-items-center gap-2">
                                                                                        <div>
                                                                                            <h5 className="fs-12 text-muted mb-0"> Members :</h5>
                                                                                        </div>
                                                                                        <div className="avatar-group">
                                                                                            <div className="avatar-group-item material-shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <img src="assets/images/users/avatar-4.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="avatar-group-item material-shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <img src="assets/images/users/avatar-5.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="avatar-group-item material-shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                                        10
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="avatar-group-item material-shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <img src="assets/images/users/avatar-2.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    
                                                                </div>
                                                                
                                                                <div className="swiper-slide">
                                                                    <div className="card profile-project-card shadow-none profile-project-danger mb-0 material-shadow">
                                                                        <div className="card-body p-4">
                                                                            <div className="d-flex">
                                                                                <div className="flex-grow-1 text-muted overflow-hidden">
                                                                                    <h5 className="fs-14 text-truncate mb-1">
                                                                                        <a href="#" className="text-body">Office 2</a>
                                                                                    </h5>
                                                                                    <p className="text-muted text-truncate mb-0"> Location : <span className="fw-semibold text-body"> </span></p>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            <div className="d-flex mt-4">
                                                                                <div className="flex-grow-1">
                                                                                    <div className="d-flex align-items-center gap-2">
                                                                                        <div>
                                                                                            <h5 className="fs-12 text-muted mb-0"> Members :</h5>
                                                                                        </div>
                                                                                        <div className="avatar-group">
                                                                                            <div className="avatar-group-item material-shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <img src="assets/images/users/avatar-2.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="avatar-group-item material-shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                                        20
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>                                                               
                                                               
                                                                
                                                            </div>

                                                        </div>

                                                    </div>
                                                    
                                                </div>

                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    <div className="tab-pane fade" id="activities" role="tabpanel">
                                        <div className="card">
                                            <div className="card-body">
                                                <h5 className="card-title mb-3">Activities</h5>
                                                <div className="acitivity-timeline">
                                                    <div className="acitivity-item d-flex">
                                                        <div className="flex-shrink-0">
                                                            <img src="assets/images/users/avatar-1.jpg" alt="" className="avatar-xs rounded-circle acitivity-avatar material-shadow" />
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h6 className="mb-1">Oliver Phillips <span className="badge bg-primary-subtle text-primary align-middle">New</span></h6>
                                                            <p className="text-muted mb-2">We talked about a project on linkedin.</p>
                                                            <small className="mb-0 text-muted">Today</small>
                                                        </div>
                                                    </div>
                                                    <div className="acitivity-item py-3 d-flex">
                                                        <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                                            <div className="avatar-title bg-success-subtle text-success rounded-circle material-shadow">
                                                                N
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h6 className="mb-1">Nancy Martino <span className="badge bg-secondary-subtle text-secondary align-middle">In Progress</span></h6>
                                                            <p className="text-muted mb-2"><i className="ri-file-text-line align-middle ms-2"></i> Create new project Buildng product</p>
                                                            <div className="avatar-group mb-2">
                                                                <a href="javascript: void(0);" className="avatar-group-item material-shadow" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Christi">
                                                                    <img src="assets/images/users/avatar-4.jpg" alt="" className="rounded-circle avatar-xs" />
                                                                </a>
                                                                <a href="javascript: void(0);" className="avatar-group-item material-shadow" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Frank Hook">
                                                                    <img src="assets/images/users/avatar-3.jpg" alt="" className="rounded-circle avatar-xs" />
                                                                </a>
                                                                <a href="javascript: void(0);" className="avatar-group-item material-shadow" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title=" Ruby">
                                                                    <div className="avatar-xs">
                                                                        <div className="avatar-title rounded-circle bg-light text-primary material-shadow">
                                                                            R
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <a href="javascript: void(0);" className="avatar-group-item material-shadow" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="more">
                                                                    <div className="avatar-xs">
                                                                        <div className="avatar-title rounded-circle">
                                                                            2+
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <small className="mb-0 text-muted">Yesterday</small>
                                                        </div>
                                                    </div>
                                                    <div className="acitivity-item py-3 d-flex">
                                                        <div className="flex-shrink-0">
                                                            <img src="assets/images/users/avatar-2.jpg" alt="" className="avatar-xs rounded-circle acitivity-avatar material-shadow" />
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h6 className="mb-1">Natasha Carey <span className="badge bg-success-subtle text-success align-middle">Completed</span>
                                                            </h6>
                                                            <p className="text-muted mb-2">Adding a new event with attachments</p>
                                                            <div className="row">
                                                                <div className="col-xxl-4">
                                                                    <div className="row border border-dashed gx-2 p-2 mb-2">
                                                                        <div className="col-4">
                                                                            <img src="assets/images/small/img-2.jpg" alt="" className="img-fluid rounded material-shadow" />
                                                                        </div>
                                                                        
                                                                        <div className="col-4">
                                                                            <img src="assets/images/small/img-3.jpg" alt="" className="img-fluid rounded material-shadow" />
                                                                        </div>
                                                                        
                                                                        <div className="col-4">
                                                                            <img src="assets/images/small/img-4.jpg" alt="" className="img-fluid rounded material-shadow" />
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                            <small className="mb-0 text-muted">25 Nov</small>
                                                        </div>
                                                    </div>
                                                    <div className="acitivity-item py-3 d-flex">
                                                        <div className="flex-shrink-0">
                                                            <img src="assets/images/users/avatar-6.jpg" alt="" className="avatar-xs rounded-circle acitivity-avatar material-shadow" />
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h6 className="mb-1">Bethany Johnson</h6>
                                                            <p className="text-muted mb-2">added a new member to velzon dashboard</p>
                                                            <small className="mb-0 text-muted">19 Nov</small>
                                                        </div>
                                                    </div>
                                                    <div className="acitivity-item py-3 d-flex">
                                                        <div className="flex-shrink-0">
                                                            <div className="avatar-xs acitivity-avatar">
                                                                <div className="avatar-title rounded-circle bg-danger-subtle text-danger material-shadow">
                                                                    <i className="ri-shopping-bag-line"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h6 className="mb-1">Your order is placed <span className="badge bg-danger-subtle text-danger align-middle ms-1">Out of Delivery</span></h6>
                                                            <p className="text-muted mb-2">These customers can rest assured their order has been placed.</p>
                                                            <small className="mb-0 text-muted">16 Nov</small>
                                                        </div>
                                                    </div>
                                                    <div className="acitivity-item py-3 d-flex">
                                                        <div className="flex-shrink-0">
                                                            <img src="assets/images/users/avatar-7.jpg" alt="" className="avatar-xs rounded-circle acitivity-avatar material-shadow" />
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h6 className="mb-1">Lewis Pratt</h6>
                                                            <p className="text-muted mb-2">They all have something to say
                                                                beyond the words on the page. They can come across as
                                                                casual or neutral, exotic or graphic. </p>
                                                            <small className="mb-0 text-muted">22 Oct</small>
                                                        </div>
                                                    </div>
                                                    <div className="acitivity-item py-3 d-flex">
                                                        <div className="flex-shrink-0">
                                                            <div className="avatar-xs acitivity-avatar">
                                                                <div className="avatar-title rounded-circle bg-info-subtle text-info material-shadow">
                                                                    <i className="ri-line-chart-line"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h6 className="mb-1">Monthly sales report</h6>
                                                            <p className="text-muted mb-2">
  																<span className="text-danger">2 days left</span> notification to submit the monthly sales report. <a href="javascript:void(0);" className="link-warning text-decoration-underline">Reports Builder</a>
                                                            </p>
                                                            <small className="mb-0 text-muted">15 Oct</small>
                                                        </div>
                                                    </div>
                                                    <div className="acitivity-item d-flex">
                                                        <div className="flex-shrink-0">
                                                            <img src="assets/images/users/avatar-8.jpg" alt="" className="avatar-xs rounded-circle acitivity-avatar material-shadow" />
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h6 className="mb-1">New ticket received <span className="badge bg-success-subtle text-success align-middle">Completed</span></h6>
                                                            <p className="text-muted mb-2">User <span className="text-secondary">Erica245</span> submitted a ticket.</p>
                                                            <small className="mb-0 text-muted">26 Aug</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                   
                                    <div className="tab-pane fade" id="projects" role="tabpanel">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-xxl-3 col-sm-6">
                                                        <div className="card profile-project-card shadow-none profile-project-warning material-shadow">
                                                            <div className="card-body p-4">
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                                                        <h5 className="fs-14 text-truncate"><a href="#" className="text-body">Chat App Update</a></h5>
                                                                        <p className="text-muted text-truncate mb-0">Last Update : <span className="fw-semibold text-body">2 year Ago</span></p>
                                                                    </div>
                                                                    <div className="flex-shrink-0 ms-2">
                                                                        <div className="badge bg-warning-subtle text-warning fs-10">Inprogress</div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mt-4">
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <div>
                                                                                <h5 className="fs-12 text-muted mb-0">Members :</h5>
                                                                            </div>
                                                                            <div className="avatar-group">
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-1.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-3.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                            J
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div className="col-xxl-3 col-sm-6">
                                                        <div className="card profile-project-card shadow-none profile-project-success material-shadow">
                                                            <div className="card-body p-4">
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                                                        <h5 className="fs-14 text-truncate"><a href="#" className="text-body">ABC Project Customization</a></h5>
                                                                        <p className="text-muted text-truncate mb-0">Last Update : <span className="fw-semibold text-body">2 month Ago</span></p>
                                                                    </div>
                                                                    <div className="flex-shrink-0 ms-2">
                                                                        <div className="badge bg-primary-subtle text-primary fs-10"> Progress</div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mt-4">
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <div>
                                                                                <h5 className="fs-12 text-muted mb-0">Members :</h5>
                                                                            </div>
                                                                            <div className="avatar-group">
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-8.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-7.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-6.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <div className="avatar-title rounded-circle bg-primary">
                                                                                            2+
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div className="col-xxl-3 col-sm-6">
                                                        <div className="card profile-project-card shadow-none profile-project-info material-shadow">
                                                            <div className="card-body p-4">
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                                                        <h5 className="fs-14 text-truncate"><a href="#" className="text-body">Client - Frank Hook</a></h5>
                                                                        <p className="text-muted text-truncate mb-0">Last Update : <span className="fw-semibold text-body">1 hr Ago</span></p>
                                                                    </div>
                                                                    <div className="flex-shrink-0 ms-2">
                                                                        <div className="badge bg-info-subtle text-info fs-10">New</div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mt-4">
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <div>
                                                                                <h5 className="fs-12 text-muted mb-0"> Members :</h5>
                                                                            </div>
                                                                            <div className="avatar-group">
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-4.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                            M
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-3.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div className="col-xxl-3 col-sm-6">
                                                        <div className="card profile-project-card shadow-none profile-project-primary material-shadow">
                                                            <div className="card-body p-4">
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                                                        <h5 className="fs-14 text-truncate"><a href="#" className="text-body">Velzon Project</a></h5>
                                                                        <p className="text-muted text-truncate mb-0">Last Update : <span className="fw-semibold text-body">11 hr Ago</span></p>
                                                                    </div>
                                                                    <div className="flex-shrink-0 ms-2">
                                                                        <div className="badge bg-success-subtle text-success fs-10">Completed</div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mt-4">
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <div>
                                                                                <h5 className="fs-12 text-muted mb-0">Members :</h5>
                                                                            </div>
                                                                            <div className="avatar-group">
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-7.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-5.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div className="col-xxl-3 col-sm-6">
                                                        <div className="card profile-project-card shadow-none profile-project-danger material-shadow">
                                                            <div className="card-body p-4">
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                                                        <h5 className="fs-14 text-truncate"><a href="#" className="text-body">Brand Logo Design</a></h5>
                                                                        <p className="text-muted text-truncate mb-0">Last Update : <span className="fw-semibold text-body">10 min Ago</span></p>
                                                                    </div>
                                                                    <div className="flex-shrink-0 ms-2">
                                                                        <div className="badge bg-info-subtle text-info fs-10">New</div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mt-4">
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <div>
                                                                                <h5 className="fs-12 text-muted mb-0">Members :</h5>
                                                                            </div>
                                                                            <div className="avatar-group">
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-7.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-6.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                            E
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div className="col-xxl-3 col-sm-6">
                                                        <div className="card profile-project-card shadow-none profile-project-primary material-shadow">
                                                            <div className="card-body p-4">
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                                                        <h5 className="fs-14 text-truncate"><a href="#" className="text-body">Chat App</a></h5>
                                                                        <p className="text-muted text-truncate mb-0">Last Update : <span className="fw-semibold text-body">8 hr Ago</span></p>
                                                                    </div>
                                                                    <div className="flex-shrink-0 ms-2">
                                                                        <div className="badge bg-warning-subtle text-warning fs-10">Inprogress</div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mt-4">
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <div>
                                                                                <h5 className="fs-12 text-muted mb-0">Members :</h5>
                                                                            </div>
                                                                            <div className="avatar-group">
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                            R
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-3.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-8.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div className="col-xxl-3 col-sm-6">
                                                        <div className="card profile-project-card shadow-none profile-project-warning material-shadow">
                                                            <div className="card-body p-4">
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                                                        <h5 className="fs-14 text-truncate"><a href="#" className="text-body">Project Update</a></h5>
                                                                        <p className="text-muted text-truncate mb-0">Last Update : <span className="fw-semibold text-body">48 min Ago</span></p>
                                                                    </div>
                                                                    <div className="flex-shrink-0 ms-2">
                                                                        <div className="badge bg-warning-subtle text-warning fs-10">Inprogress</div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mt-4">
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <div>
                                                                                <h5 className="fs-12 text-muted mb-0">Members :</h5>
                                                                            </div>
                                                                            <div className="avatar-group">
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-6.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-5.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-4.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div className="col-xxl-3 col-sm-6">
                                                        <div className="card profile-project-card shadow-none profile-project-success material-shadow">
                                                            <div className="card-body p-4">
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                                                        <h5 className="fs-14 text-truncate"><a href="#" className="text-body">Client - Jennifer</a></h5>
                                                                        <p className="text-muted text-truncate mb-0">Last Update : <span className="fw-semibold text-body">30 min Ago</span></p>
                                                                    </div>
                                                                    <div className="flex-shrink-0 ms-2">
                                                                        <div className="badge bg-primary-subtle text-primary fs-10">Process</div>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex mt-4">
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <div>
                                                                                <h5 className="fs-12 text-muted mb-0"> Members :</h5>
                                                                            </div>
                                                                            <div className="avatar-group">
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-1.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div className="col-xxl-3 col-sm-6">
                                                        <div className="card profile-project-card shadow-none mb-xxl-0 profile-project-info material-shadow">
                                                            <div className="card-body p-4">
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                                                        <h5 className="fs-14 text-truncate"><a href="#" className="text-body">Bsuiness Template - UI/UX design</a></h5>
                                                                        <p className="text-muted text-truncate mb-0">Last Update : <span className="fw-semibold text-body">7 month Ago</span></p>
                                                                    </div>
                                                                    <div className="flex-shrink-0 ms-2">
                                                                        <div className="badge bg-success-subtle text-success fs-10">Completed</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex mt-4">
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <div>
                                                                                <h5 className="fs-12 text-muted mb-0">Members :</h5>
                                                                            </div>
                                                                            <div className="avatar-group">
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-2.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-3.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-4.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <div className="avatar-title rounded-circle bg-primary">
                                                                                            2+
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                    <div className="col-xxl-3 col-sm-6">
                                                        <div className="card profile-project-card shadow-none mb-xxl-0  profile-project-success material-shadow">
                                                            <div className="card-body p-4">
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                                                        <h5 className="fs-14 text-truncate"><a href="#" className="text-body">Update Project</a></h5>
                                                                        <p className="text-muted text-truncate mb-0">Last Update : <span className="fw-semibold text-body">1 month Ago</span></p>
                                                                    </div>
                                                                    <div className="flex-shrink-0 ms-2">
                                                                        <div className="badge bg-info-subtle text-info fs-10">New</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex mt-4">
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <div>
                                                                                <h5 className="fs-12 text-muted mb-0">Members :</h5>
                                                                            </div>
                                                                            <div className="avatar-group">
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-7.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                                                                            A
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-xxl-3 col-sm-6">
                                                        <div className="card profile-project-card shadow-none mb-sm-0  profile-project-danger material-shadow">
                                                            <div className="card-body p-4">
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                                                        <h5 className="fs-14 text-truncate"><a href="#" className="text-body">Bank Management System</a></h5>
                                                                        <p className="text-muted text-truncate mb-0">Last Update : <span className="fw-semibold text-body">10 month Ago</span></p>
                                                                    </div>
                                                                    <div className="flex-shrink-0 ms-2">
                                                                        <div className="badge bg-success-subtle text-success fs-10">Completed</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex mt-4">
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <div>
                                                                                <h5 className="fs-12 text-muted mb-0">Members :</h5>
                                                                            </div>
                                                                            <div className="avatar-group">
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-7.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-6.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-5.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <div className="avatar-title rounded-circle bg-primary">
                                                                                            2+
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-xxl-3 col-sm-6">
                                                        <div className="card profile-project-card shadow-none mb-0  profile-project-primary material-shadow">
                                                            <div className="card-body p-4">
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                                                        <h5 className="fs-14 text-truncate"><a href="#" className="text-body">PSD to HTML Convert</a></h5>
                                                                        <p className="text-muted text-truncate mb-0">Last Update : <span className="fw-semibold text-body">29 min Ago</span></p>
                                                                    </div>
                                                                    <div className="flex-shrink-0 ms-2">
                                                                        <div className="badge bg-info-subtle text-info fs-10">New</div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex mt-4">
                                                                    <div className="flex-grow-1">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <div>
                                                                                <h5 className="fs-12 text-muted mb-0">Members :</h5>
                                                                            </div>
                                                                            <div className="avatar-group">
                                                                                <div className="avatar-group-item material-shadow">
                                                                                    <div className="avatar-xs">
                                                                                        <img src="assets/images/users/avatar-7.jpg" alt="" className="rounded-circle img-fluid" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-lg-12">
                                                        <div className="mt-4">
                                                            <ul className="pagination pagination-separated justify-content-center mb-0">
                                                                <li className="page-item disabled">
                                                                    <a href="javascript:void(0);" className="page-link"><i className="mdi mdi-chevron-left"></i></a>
                                                                </li>
                                                                <li className="page-item active">
                                                                    <a href="javascript:void(0);" className="page-link">1</a>
                                                                </li>
                                                                <li className="page-item">
                                                                    <a href="javascript:void(0);" className="page-link">2</a>
                                                                </li>
                                                                <li className="page-item">
                                                                    <a href="javascript:void(0);" className="page-link">3</a>
                                                                </li>
                                                                <li className="page-item">
                                                                    <a href="javascript:void(0);" className="page-link">4</a>
                                                                </li>
                                                                <li className="page-item">
                                                                    <a href="javascript:void(0);" className="page-link">5</a>
                                                                </li>
                                                                <li className="page-item">
                                                                    <a href="javascript:void(0);" className="page-link"><i className="mdi mdi-chevron-right"></i></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    
                                    <div className="tab-pane fade" id="documents" role="tabpanel">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-4">
                                                    <h5 className="card-title flex-grow-1 mb-0">Documents</h5>
                                                    <div className="flex-shrink-0">
                                                        <input className="form-control d-none" type="file" id="formFile" />
                                                        <label htmlFor="formFile" className="btn btn-danger"><i className="ri-upload-2-fill me-1 align-bottom"></i> Upload File</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="table-responsive">
                                                            <table className="table table-borderless align-middle mb-0">
                                                                <thead className="table-light">
                                                                    <tr>
                                                                        <th scope="col">File Name</th>
                                                                        <th scope="col">Type</th>
                                                                        <th scope="col">Size</th>
                                                                        <th scope="col">Upload Date</th>
                                                                        <th scope="col">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="avatar-sm">
                                                                                    <div className="avatar-title bg-primary-subtle text-primary rounded fs-20 material-shadow">
                                                                                        <i className="ri-file-zip-fill"></i>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ms-3 flex-grow-1">
                                                                                    <h6 className="fs-15 mb-0"><a href="javascript:void(0)">Artboard-documents.zip</a>
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>Zip File</td>
                                                                        <td>4.57 MB</td>
                                                                        <td>12 Dec 2021</td>
                                                                        <td>
                                                                            <div className="dropdown">
                                                                                <a href="javascript:void(0);" className="btn btn-light btn-icon" id="dropdownMenuLink15" data-bs-toggle="dropdown" aria-expanded="true">
                                                                                    <i className="ri-equalizer-fill"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink15">
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill me-2 align-middle text-muted"></i>View</a></li>
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-download-2-fill me-2 align-middle text-muted"></i>Download</a></li>
                                                                                    <li className="dropdown-divider"></li>
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="avatar-sm">
                                                                                    <div className="avatar-title bg-danger-subtle text-danger rounded fs-20 material-shadow">
                                                                                        <i className="ri-file-pdf-fill"></i>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ms-3 flex-grow-1">
                                                                                    <h6 className="fs-15 mb-0"><a href="javascript:void(0);">Bank Management System</a></h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>PDF File</td>
                                                                        <td>8.89 MB</td>
                                                                        <td>24 Nov 2021</td>
                                                                        <td>
                                                                            <div className="dropdown">
                                                                                <a href="javascript:void(0);" className="btn btn-light btn-icon" id="dropdownMenuLink3" data-bs-toggle="dropdown" aria-expanded="true">
                                                                                    <i className="ri-equalizer-fill"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink3">
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill me-2 align-middle text-muted"></i>View</a></li>
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-download-2-fill me-2 align-middle text-muted"></i>Download</a></li>
                                                                                    <li className="dropdown-divider"></li>
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="avatar-sm">
                                                                                    <div className="avatar-title bg-secondary-subtle text-secondary rounded fs-20 material-shadow">
                                                                                        <i className="ri-video-line"></i>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ms-3 flex-grow-1">
                                                                                    <h6 className="fs-15 mb-0"><a href="javascript:void(0);">Tour-video.mp4</a></h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>MP4 File</td>
                                                                        <td>14.62 MB</td>
                                                                        <td>19 Nov 2021</td>
                                                                        <td>
                                                                            <div className="dropdown">
                                                                                <a href="javascript:void(0);" className="btn btn-light btn-icon" id="dropdownMenuLink4" data-bs-toggle="dropdown" aria-expanded="true">
                                                                                    <i className="ri-equalizer-fill"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink4">
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill me-2 align-middle text-muted"></i>View</a></li>
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-download-2-fill me-2 align-middle text-muted"></i>Download</a></li>
                                                                                    <li className="dropdown-divider"></li>
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="avatar-sm">
                                                                                    <div className="avatar-title bg-success-subtle text-success rounded fs-20 material-shadow">
                                                                                        <i className="ri-file-excel-fill"></i>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ms-3 flex-grow-1">
                                                                                    <h6 className="fs-15 mb-0"><a href="javascript:void(0);">Account-statement.xsl</a></h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>XSL File</td>
                                                                        <td>2.38 KB</td>
                                                                        <td>14 Nov 2021</td>
                                                                        <td>
                                                                            <div className="dropdown">
                                                                                <a href="javascript:void(0);" className="btn btn-light btn-icon" id="dropdownMenuLink5" data-bs-toggle="dropdown" aria-expanded="true">
                                                                                    <i className="ri-equalizer-fill"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink5">
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill me-2 align-middle text-muted"></i>View</a></li>
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-download-2-fill me-2 align-middle text-muted"></i>Download</a></li>
                                                                                    <li className="dropdown-divider"></li>
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-5-line me-2 align-middle text-muted"></i>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="avatar-sm">
                                                                                    <div className="avatar-title bg-info-subtle text-info rounded fs-20 material-shadow">
                                                                                        <i className="ri-folder-line"></i>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ms-3 flex-grow-1">
                                                                                    <h6 className="fs-15 mb-0"><a href="javascript:void(0);">Project Screenshots Collection</a></h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>Floder File</td>
                                                                        <td>87.24 MB</td>
                                                                        <td>08 Nov 2021</td>
                                                                        <td>
                                                                            <div className="dropdown">
                                                                                <a href="javascript:void(0);" className="btn btn-light btn-icon" id="dropdownMenuLink6" data-bs-toggle="dropdown" aria-expanded="true">
                                                                                    <i className="ri-equalizer-fill"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink6">
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill me-2 align-middle"></i>View</a></li>
                                                                                    <li>
                                                                                        <a className="dropdown-item" href="javascript:void(0);"><i className="ri-download-2-fill me-2 align-middle"></i>Download</a>
                                                                                    </li>
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-5-line me-2 align-middle"></i>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="avatar-sm">
                                                                                    <div className="avatar-title bg-danger-subtle text-danger rounded fs-20 material-shadow">
                                                                                        <i className="ri-image-2-fill"></i>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ms-3 flex-grow-1">
                                                                                    <h6 className="fs-15 mb-0">
                                                                                        <a href="javascript:void(0);">Velzon-logo.png</a>
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>PNG File</td>
                                                                        <td>879 KB</td>
                                                                        <td>02 Nov 2021</td>
                                                                        <td>
                                                                            <div className="dropdown">
                                                                                <a href="javascript:void(0);" className="btn btn-light btn-icon" id="dropdownMenuLink7" data-bs-toggle="dropdown" aria-expanded="true">
                                                                                    <i className="ri-equalizer-fill"></i>
                                                                                </a>
                                                                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink7">
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill me-2 align-middle"></i>View</a></li>
                                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-download-2-fill me-2 align-middle"></i>Download</a></li>
                                                                                    <li>
                                                                                        <a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-5-line me-2 align-middle"></i>Delete</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="text-center mt-3">
                                                            <a href="javascript:void(0);" className="text-success"><i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load more </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                               
                            </div>
                        </div>
                        
                    </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorViewTeammember;
