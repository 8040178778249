import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Tabs from "../../components/Tabs";
import PlanProfiles from "../../components/PlanProfiles";
import Permissions from "../../components/Permissions";
import ProfileGroups from "../../components/ProfileGroups";

interface Plan {
  id: string;
  planName: string;
  description: string;
}
interface LicenseType {
  id: string;
  name: string;
  code: string;
}

const SuperAdminProfiles = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const token = localStorage.getItem('sessionToken');

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch('https://dev.reelty.app/api/v1/plan-profiles', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setPlans(data.plans);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    const fetchLicenseTypes = async () => {
      try {
        const response = await fetch('https://dev.reelty.app/api/v1/license-types', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setLicenseTypes(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseTypes();
  }, []);

  // Function to delete a plan
  const deletePlan = async (id: string) => {
    try {
      const response = await fetch(`https://dev.reelty.app/api/v1/plan-profile/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to delete plan');
      }
      // Remove the plan from the state
      setPlans(plans.filter(plan => plan.id !== id));
      console.log(`Plan with ID ${id} deleted successfully`);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred");
      }
    }
  };

  return (
    <div className="roles">
      <div className="card">
        <div className="card-body">
          <h2>Plans</h2>
          <Tabs>
            <PlanProfiles plans={plans} loading={loading} error={error} deletePlan={deletePlan} />
            <Permissions plans={plans} licenseTypes={licenseTypes} />
            <ProfileGroups />
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminProfiles;
